import React from 'react'

const Footer = (): JSX.Element => (
  <div className="footer">
    <div className="container py-4">
      <div className="row mt-5">
        <div className="col-12 text-center">
          {/* <span>
            Managed by<a href="https://www.genius-websites.de">Genius-Websites.de</a>
          </span> */}
          <span>{`© Copyright ${new Date().getFullYear()}`}</span>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
