import React from 'react'
import { Link } from 'gatsby'
// import Img from 'gatsby-image'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import useToggle from '../hooks/useToggle'

import LogoComponent from '../assets/svg/va_logo_only.svg'

const iconSize = '24px'
const linkClassNames = `nav-item nav-link mx-1 px-2`
const urlShopEtsy = 'https://www.etsy.com/shop/BoahGUSCH'
const urlShopInstagram = 'https://www.instagram.com/veronikas.atelier'

const SocialMediaIcon = ({ link, entypoClass }) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    style={{
      width: iconSize,
      height: iconSize,
      borderRadius: iconSize,
      border: '1px solid #666',
      display: 'inline-block',
    }}
    className="text-center ml-1"
  >
    <i
      className={entypoClass}
      style={{
        fontSize: '13px',
        color: '#666',
        lineHeight: iconSize,
        top: '-1px',
      }}
    />
  </a>
)

const SavedAnchorLink = ({ pathname = undefined, anchor, label }) => {
  if (pathname !== '/')
    return (
      <Link className={linkClassNames} to={'/' + anchor}>
        {label}
      </Link>
    )
  return (
    <AnchorLink offset="100" href={anchor} className={linkClassNames}>
      {label}
    </AnchorLink>
  )
}

const NavLinks = ({ location }) => {
  return (
    <>
      <SavedAnchorLink
        pathname={location?.pathname}
        label="About"
        anchor="#about"
      />
      <Link
        to={urlShopEtsy}
        className={
          linkClassNames
        }
      >
        Shop
      </Link>
      <Link
        to={urlShopInstagram}
        className={
          linkClassNames
        }
      >
        Instagram
      </Link>
      {/* <Link
        to="/datenschutz"
        className={
          linkClassNames +
          ` ${location.pathname === '/datenschutz' ? 'active' : ''}`
        }
      >
        Datenschutz
      </Link> */}
      <Link
        to="/impressum"
        className={
          linkClassNames +
          ` ${location.pathname === '/impressum' ? 'active' : ''}`
        }
      >
        Impressum
      </Link>
    </>
  )
}

export const LogoLink = ({ textColor, iconFillClassName = 'fill-primary' }) => (
  <Link to={'/'} className="navbar-brand mr-0 d-flex align-items-center">
    <LogoComponent className={iconFillClassName} style={{ height: '50px' }} />
    <h2
      style={{
        color: textColor || 'black',
        fontSize: '2.5rem',
        lineHeight: '2.5rem',
        marginBottom: 0,
        marginLeft: '12px',
        transform: 'translateY(7px)',
      }}
    >
      Veronikas Atelier
    </h2>
    {/* <h3
      style={{
        color: textColor || 'black',
        lineHeight: '1.6rem',
        marginBottom: 0,
        marginLeft: '15px',
        transform: 'translateY(5px)',
      }}
    >
      Veronikas
      <br />
      Atelier
    </h3> */}
  </Link>
)

type Props = React.PropsWithChildren<{
  location: Location
}>

const Header = ({ location }: Props): JSX.Element => {
  const [open, toggle] = useToggle(false)
  const [hasScrolled, setHasScrolled] = React.useState(false)

  const handleScroll = () => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      setHasScrolled(true)
    } else {
      setHasScrolled(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <nav
      className={`navbar w-100 ${
        hasScrolled ? 'navbar-light' : 'navbar-transparent'
      }`}
    >
      <div className="container w-100 h-100 d-flex justify-content-between align-items-center">
        <LogoLink
          iconFillClassName={hasScrolled ? 'fill-primary' : 'fill-white'}
          textColor={hasScrolled ? 'black' : 'white'}
        />
        <button
          className={`d-md-none hamburger hamburger--spring ${
            open && 'is-active'
          }`}
          type="button"
          onClick={() => toggle()}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <ul className="nav d-none d-md-flex justify-content-center align-items-center mx-4">
          <NavLinks location={location} />
        </ul>
        {/* <SocialMedia mobile={false} /> */}
      </div>
      {open && (
        <div
          className="w-100 pb-3 d-md-none d-flex flex-column align-items-end pr-5"
          style={{
            // borderBottom: '1px solid rgb(226,0,116)',
            position: 'absolute',
            top: '75px',
            left: '0px',
            zIndex: 100000,
            backgroundColor: hasScrolled ? 'white' : 'rgba(0,0,0,0.5)',
          }}
        >
          <NavLinks location={location} />
          {/* <SocialMedia mobile={true} /> */}
        </div>
      )}
    </nav>
  )
}

export default Header
