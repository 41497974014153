import React from 'react'
import { FluidObject } from 'gatsby-image'
// import scrollTo from 'gatsby-plugin-smoothscroll'

import Header from './Header'
import Hero from './Hero'
import Footer from './Footer'
// import CookieConsent from './CookieConsent'
import '../scss/gatstrap.scss'

type Props = React.PropsWithChildren<{
  location: Location
  fluidHeroImage?: FluidObject
  publicHeroImage?: string
  showHeader?: boolean
  showHero?: boolean
  showFooter?: boolean
}>

const Layout = ({
  children,
  location,
  fluidHeroImage,
  publicHeroImage,
  showHeader = false,
  showHero = false,
  showFooter = true,
}: Props): JSX.Element => {
  return (
    <div className="bg-site d-flex flex-column min-h-screen">
      {showHeader && <Header location={location} />}
      <main
        id="main-anchor"
        // style={{ paddingTop: '80px' }}
      >
        {showHero && fluidHeroImage && <Hero publicHeroImage={publicHeroImage} fluidHeroImage={fluidHeroImage} />}
        {children}
      </main>
      {showFooter && <Footer />}
      {/* <CookieConsent /> */}
    </div>
  )
}

export default Layout
