import React from 'react'
// import BackgroundImage from 'gatsby-background-image'
import { FluidObject } from 'gatsby-image'

const Hero = ({
  fluidHeroImage,
  publicHeroImage,
}: {
  fluidHeroImage?: FluidObject
  publicHeroImage?: string
}): JSX.Element => (
  <div className="p-relative w-100 vh-100 d-flex justify-content-center align-items-center">
    {/* <BackgroundImage
      Tag="div"
      className="p-absolute-full bg-image-center"
      fluid={fluidHeroImage}
    /> */}
    <div
        className="p-absolute-full bg-image-center"
        style={{
          backgroundImage: `url(${publicHeroImage})`,
        }}
      />
    <div className="fogwrapper">
      <div id="foglayer_01" className="fog">
        <div className="image01"></div>
        <div className="image02"></div>
      </div>
      <div id="foglayer_02" className="fog">
        <div className="image01"></div>
        <div className="image02"></div>
      </div>
      <div id="foglayer_03" className="fog">
        <div className="image01"></div>
        <div className="image02"></div>
      </div>
    </div>
  </div>
)

export default Hero
